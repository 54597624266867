import { FunctionComponent, HTMLAttributes, ReactElement } from "react";
import { Container } from "@mui/material";
import Image from "next/image";

import { styled } from "@mui/system";
import installation from "./installation.jpg";

interface Properties extends HTMLAttributes<HTMLElement> {}

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: "104px",
    [theme.breakpoints.down("sm")]: {
        marginTop: "64px",
    },
}));

export const Installation: FunctionComponent<Properties> = ({
    children,
    className,
    ...rest
}: Properties): ReactElement => {
    return (
        <StyledContainer
            className={className}
            {...rest}
            data-testid="installation-wrapper"
        >
            <Image
                width={1312}
                height={856}
                src={installation}
                alt="Paul Kneale"
                data-testid="author-image"
            />
        </StyledContainer>
    );
};
